.parent {
  position: relative;
}

.bottom {
  position: absolute;
  bottom: 0;
  right: 0;
}

.swal2-container {
  z-index: 2147483647 !important;
}

.swal2-input {
  z-index: 2147483647 !important;
}

.combinationsTable {
  bottom: 0;
  left: 0;
}
