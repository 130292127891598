.searchArea,
.search,
.search input {
  width: auto !important;
  padding: 0 !important;
  margin: 0 !important;
  -webkit-border-radius: none !important;
  -moz-border-radius: none !important;
  -ms-border-radius: none !important;
  -o-border-radius: none !important;
  border-radius: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  -ms-box-shadow: none !important;
  -o-box-shadow: none !important;
  box-shadow: none !important;
  border: none !important;
  background-color: transparent !important;
}

.searchArea {
  width: 30%;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  height: 25.5px !important;
}
.search {
  height: 25.5px !important;
  border: none !important;
  -wekit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  -ms-box-shadow: none !important;
  -o-box-shadow: none !important;
  box-shadow: none !important;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 0px;
  border-bottom: 2px solid !important;
  padding: 10px 60px 10px 20px;
}

.search:hover,
.search:focus {
  border: none !important;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 0px !important;
  border-bottom: 2px solid !important;
  border-image-slice: 1 !important;
  border-image-source: -webkit-linear-gradient(
    to right,
    var(--background2),
    var(--background3)
  ) !important;
  border-image-source: -moz-linear-gradient(
    to right,
    var(--background2),
    var(--background3)
  ) !important;
  border-image-source: -ms-linear-gradient(
    to right,
    var(--background2),
    var(--background3)
  ) !important;
  border-image-source: -o-linear-gradient(
    to right,
    var(--background2),
    var(--background3)
  ) !important;
  border-image-source: linear-gradient(
    to right,
    var(--background2),
    var(--background3)
  ) !important;
}

.search input {
  height: 24px !important;
  border: none !important;
  color: rgba(29, 160, 242, 0.945);

  /* border: 1px solid blue;*/
}

/* .search input[placeholder] {
  color: var(--textColor);
} */

.searchArea button,
.searchArea .closeIcon {
  position: relative;
  right: 0px;
  bottom: 2px;
  padding: 2px !important;
  color: var(--background2) !important;
  width: 26px !important;
  height: 26px !important;
  -webkit-border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  -ms-border-radius: 50% !important;
  -o-border-radius: 50% !important;
  border-radius: 50% !important;
  font-size: 1rem !important;
  background-color: transparent;
}

.searchArea .closeIcon {
  top: 2px !important;
  padding: 0 !important;
  width: 24px !important;
  height: 24px !important;
  -webkit-border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  -ms-border-radius: 50% !important;
  -o-border-radius: 50% !important;
  border-radius: 50% !important;
  font-size: 1rem !important;
  font-weight: bold;
  background-color: transparent;
}
