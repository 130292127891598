//
// Modal
//


// Fluid modal

.modal-fluid {
    .modal-dialog { //Added for Dynamic Model Size 
        margin-top: 0;
        margin-bottom: 0;
        min-width: 100%;
        margin-left: 80;
    }
    .modal-content { //Added for Dynamic Model Size 
        border-radius: 0;
        min-height: 100vh;
//      word-break: break-all;
     word-break: break-word
    }
}


// Background color variations

@each $color, $value in $theme-colors {
    .modal-#{$color} {
        @include modal-variant($value);
    }
}
