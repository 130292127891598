/*----  Main Style  ----*/
#cards_landscape_wrap-2 {
  /* text-align: center; */
  background: #f7f7f7;
}
#cards_landscape_wrap-2 .container {
  /* padding-top: 80px;  */
  padding-bottom: 100px;
}
#cards_landscape_wrap-2 a {
  text-decoration: none;
  outline: none;
}
#cards_landscape_wrap-2 .card-flyer {
  border-radius: 5px;
}
#cards_landscape_wrap-2 .card-flyer .image-box {
  background: #ffffff;
  overflow: hidden;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}
#cards_landscape_wrap-2 .card-flyer .image-box img {
  -webkit-transition: all 0.9s ease;
  -moz-transition: all 0.9s ease;
  -o-transition: all 0.9s ease;
  -ms-transition: all 0.9s ease;
  width: 100%;
  height: 200px;
}
#cards_landscape_wrap-2 .card-flyer:hover .image-box img {
  opacity: 0.7;
  -webkit-transform: scale(1.15);
  -moz-transform: scale(1.15);
  -ms-transform: scale(1.15);
  -o-transform: scale(1.15);
  transform: scale(1.15);
}
#cards_landscape_wrap-2 .card-flyer .text-box {
  text-align: center;
}
#cards_landscape_wrap-2 .card-flyer .text-box .text-container {
  padding: 30px 18px;
}
#cards_landscape_wrap-2 .card-flyer {
  background: #ffffff;
  margin-top: 50px;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.4);
}
#cards_landscape_wrap-2 .card-flyer:hover {
  background: #fff;
  box-shadow: 0px 15px 26px rgba(0, 0, 0, 0.5);
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  margin-top: 50px;
}
#cards_landscape_wrap-2 .card-flyer .text-box p {
  margin-top: 10px;
  margin-bottom: 0px;
  padding-bottom: 0px;
  font-size: 14px;
  letter-spacing: 1px;
  color: #000000;
}
#cards_landscape_wrap-2 .card-flyer .text-box h6 {
  margin-top: 0px;
  margin-bottom: 4px;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  font-family: 'Roboto Black', sans-serif;
  letter-spacing: 1px;
  color: #00acc1;
}

.card1:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.wrapper {
  position: relative;
  /* background-image: linear-gradient(
        315deg,
        rgba(5, 13, 20, 0.8) 0%, 
        rgba(63, 61, 62, 0.8) 74%); */
  /* width: 280px;
    height: 350px; */
  /* background: url('https://cdn.pixabay.com/photo/2018/03/30/15/11/deer-3275594_960_720.jpg') 0 0 no-repeat; */
  background: #141e30; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #243b55,
    #141e30
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #243b55,
    #141e30
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  background-size: cover;
  overflow: hidden;
}

.my-modal {
  width: 90vw; /* Occupy the 90% of the screen width */
  max-width: 90vw;
  /* background-color: #525f7f; */
}
/* .modal-body {
  background-color: #5e72e4;
} */

.headerInline {
  display: inline;
}

.headerMarginLeft {
  float: left;
}

.textSizeHeader {
  font-size: 15px;
  font-weight: bold;
}
.textSizeHeader:hover {
  color: #558a91;
}

.button-styling {
  background: none;
  border: none;
  font-weight: bold;
}
.button-styling:hover {
  color: #1038bd;
}

.disable-styling {
  /* color:rgb(189, 50, 50); */
  /* color: #FFA07a; */
  color: #32325d;
}

.link-align {
  display: inline;
  margin-right: 1em;
  font-weight: bold;
}


.RichText-header {
	background-color: #282c34;
	min-height: 5vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
	margin-bottom: 5vh;
	text-align: center;
      }

      .wrapper-class {
	padding: 1rem;
	border: 1px solid #ccc;
      }
      .editor-class {
	background-color:lightgray;
	padding: 1rem;
	border: 1px solid #ccc;
      }
      .toolbar-class {
	border: 1px solid #ccc;
      }
     